import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {session} from "../../api/ApiSession";
import {Invoice} from "../../api/model/billing/Invoice";
import {Color, ContentBlock, LayoutRows, MainContentPageHeader, MainContentStyle, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {InvoiceRow} from "../../component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {monthFormat, TranslationPortalFile} from "../../utils/constants";
import {Interval} from "../../utils/Interval";
import {InvoicePeriod, INVOICES_INTERVALS} from "../../utils/InvoicePeriod";
import {MainHeader} from "../../common/component/snippet";
import {PORTAL} from "../../utils/Portal";
import {Theme} from "../../common/utils/Theme";

const START_DATE = moment().subtract(12, "months").format(monthFormat);
const END_DATE = moment().subtract(1, "month").format(monthFormat);

function BillingInvoices() {
    const {t: textBillingInvoices} = useTranslation(TranslationPortalFile.BILLING_INVOICES);

    const [period, setPeriod] = useState<InvoicePeriod>(InvoicePeriod.LAST_12_MONTHS);
    const [interval, setInterval] = useState<Interval>({start: START_DATE, end: END_DATE});
    const [monthlyInvoices, setMonthlyInvoices] = useState<Invoice[]>([]);

    useEffect(() => {
        (async function () {
            try {
                const result = await session.restBilling.getInvoices(interval.start, interval.end);
                let invoices: Invoice[] = [];
                result.forEach((item) => {
                    const invoicesWithCurrency = item.invoices.map((invoice) => new Invoice({...invoice, currency: item.currency}));
                    invoices = [...invoices, ...invoicesWithCurrency];
                });
                setMonthlyInvoices(invoices);
            } catch (e) {
            }
        })();
    }, [interval]);

    const handleChangePeriod = (value: InvoicePeriod) => {
        setPeriod(value);
        let start = "";
        switch (value) {
            case InvoicePeriod.LAST_12_MONTHS:
                start = moment(END_DATE).subtract(13, "months").format(monthFormat);
                break;
            case InvoicePeriod.LAST_24_MONTHS:
                start = moment(END_DATE).subtract(25, "months").format(monthFormat);
                break;
            case InvoicePeriod.LAST_36_MONTHS:
                start = moment(END_DATE).subtract(37, "months").format(monthFormat);
                break;
            case InvoicePeriod.ALL:
                start = "2012-12";
                break;
        }
        setInterval({start: start, end: END_DATE});
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textBillingInvoices("title")}
                    icon={{name: "receipt_long", colorIcon: Color.OCEAN, outlined: true}}
                />
                <LayoutRows>
                    <ContentBlock
                        header={{actions: [
                            <Select
                                key={"period-input-select"}
                                value={period}
                                onChange={(option) => handleChangePeriod(option?.value as InvoicePeriod)}
                                options={
                                    INVOICES_INTERVALS.map((it) => ({
                                        value: it,
                                        label: textBillingInvoices(`period.${it}`)
                                    }))
                                }
                            />
                        ]
                        }}
                    >
                        <Table
                            columns={[
                                {width: 15, label: textBillingInvoices("field.month"), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 20, label: textBillingInvoices("field.product"), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 35, label: textBillingInvoices("field.reference")},
                                {width: 25, label: textBillingInvoices("field.total_amount"), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 5}
                            ]}
                            themeWhite={PORTAL.theme === Theme.SIRDATA_WHITE}
                        >
                            {monthlyInvoices.map((invoice) =>
                                <InvoiceRow key={invoice.id} invoice={invoice}/>)}
                        </Table>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default BillingInvoices;
