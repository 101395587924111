import {FC, useEffect, useState} from "react";
import {Box, BoxSpacing, ButtonLink, ButtonLinkStyle, Color, FlexContentMobileDirection, Icon, IconProps, LayoutColumns, LayoutRows, Loadable, MainContentPageHeader, MainContentStyle} from "@sirdata/ui-lib";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Account} from "../common/api/model/account/Account";
import {AccountType} from "../common/api/model/account/AccountType";
import {Authorization} from "../api/model/account/Authorization";
import {MainContent, RestrictedContent, Wrapper} from "../common/component/widget";
import {MainHeader} from "../common/component/snippet";
import {PartnerAccount} from "../api/model/account/PartnerAccount";
import {pathAccessAccounts, pathAccessApiTokens, pathBillingInfo, pathBillingInvoices, pathCredentials, pathInformation, pathTrafficDaily, pathTrafficGlobal, TranslationPortalFile} from "../utils/constants";
import {session} from "../api/ApiSession";
import {SvgHomeIllustration} from "../component/svg";

function Home() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);
    const history = useHistory();

    const [isLoading, setLoading] = useState(true);
    const [loggedAccount, setLoggedAccount] = useState<Account>();
    const [partnerAccount, setPartnerAccount] = useState<PartnerAccount>();

    useEffect(() => {
        (async function () {
            try {
                const loggedAccount = await session.getAccount();
                setLoggedAccount(loggedAccount);

                const partnerAccount = await session.restPartnerAccount.get();
                setPartnerAccount(partnerAccount);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const MenuSection: FC<{ icon: IconProps; title: string; links: { label: string; path: string }[] }> = (props) => {
        const {icon, title, links} = props;

        return (
            <div className="home-box__section">
                <div className="home-box__section__title">
                    <Icon {...icon}/>
                    <span className="h3">{title}</span>
                </div>
                <ul className="home-box__section__list">
                    {links.map((it) => (
                        <li key={it.label}>
                            <ButtonLink style={ButtonLinkStyle.MIDNIGHT_LIGHT} onClick={() => history.push(it.path)}>{it.label}</ButtonLink>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textHome("title", {name: partnerAccount?.displayed_name})}
                    description={textHome("description")}
                />
                <LayoutRows>
                    <LayoutColumns mobileDirection={FlexContentMobileDirection.COLUMN_REVERSE}>
                        <div>
                            <SvgHomeIllustration/>
                        </div>
                        <Loadable loading={isLoading}>
                            <Box spacing={BoxSpacing.LARGE} cssClass="home-box">
                                <MenuSection
                                    title={t("menu.information")}
                                    icon={{name: "account_circle", colorIcon: Color.CYAN, outlined: true}}
                                    links={[
                                        {label: textHome("link.update_information"), path: pathInformation}
                                    ]}
                                />
                                <MenuSection
                                    title={t("menu.credentials")}
                                    icon={{name: "lock_open", outlined: true, colorIcon: Color.CYAN}}
                                    links={[
                                        {label: textHome("link.update_credentials"), path: pathCredentials}
                                    ]}
                                />
                                {(loggedAccount?.type === AccountType.OWNER || loggedAccount?.type === AccountType.ADMIN) &&
                                    <>
                                        <MenuSection
                                            title={t("menu.access")}
                                            icon={{name: "manage_accounts", colorIcon: Color.OCEAN, outlined: true}}
                                            links={[
                                                {label: t("menu.access_accounts"), path: pathAccessAccounts},
                                                {label: t("menu.access_api-tokens"), path: pathAccessApiTokens}
                                            ]}
                                        />
                                        <RestrictedContent allowedTo={Authorization.TRAFFIC}>
                                            <MenuSection
                                                title={t("menu.traffic")}
                                                icon={{name: "route", colorIcon: Color.OCEAN, outlined: true}}
                                                links={[
                                                    {label: t("menu.traffic_global"), path: pathTrafficGlobal},
                                                    {label: t("menu.traffic_daily"), path: pathTrafficDaily}
                                                ]}
                                            />
                                        </RestrictedContent>
                                        <MenuSection
                                            title={t("menu.billing")}
                                            icon={{name: "receipt_long", colorIcon: Color.OCEAN, outlined: true}}
                                            links={[
                                                {label: t("menu.billing_info"), path: pathBillingInfo},
                                                {label: t("menu.billing_invoices"), path: pathBillingInvoices}
                                            ]}
                                        />
                                    </>
                                }
                            </Box>
                        </Loadable>
                    </LayoutColumns>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Home;
