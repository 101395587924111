import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Line} from "react-chartjs-2";
import moment from "moment";
import {session} from "../../api/ApiSession";
import {IncomeItem} from "../../api/model/incomes/Income";
import {monthFormat, TranslationPortalFile} from "../../utils/constants";
import {GraphData, optionsLinesMonthlyIncome} from "../../utils/graph_options";
import {Locale} from "../../common/utils/Locale";
import {Box, BoxRadius, ContentBlock, Loadable, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {ButtonDownload, IncomeRow} from "../../component/snippet";
import {PORTAL} from "../../utils/Portal";
import {Theme} from "../../common/utils/Theme";

const IncomeSection: FunctionComponent<{ partnerId: number }> = ({partnerId}) => {
    const {t: textTrafficGlobal} = useTranslation(TranslationPortalFile.TRAFFIC_GLOBAL);

    const [isLoading, setLoading] = useState(true);
    const [incomes, setIncomes] = useState<IncomeItem[]>();
    const [chartIncomesData, setChartIncomesData] = useState<GraphData>();

    useEffect(() => {
        const buildGraphIncomes = (source: IncomeItem[]) => {
            const labels: string[] = [];
            const dataIncomes: number[] = [];

            for (let stat of source) {
                labels.push(stat.month);
                dataIncomes.push(stat.hash_incomes);
            }

            setChartIncomesData({
                labels: labels, datasets: [
                    {
                        label: textTrafficGlobal("table.incomes"),
                        backgroundColor: "rgb(253, 233, 181, .5)",
                        borderWidth: 1,
                        data: dataIncomes
                    }
                ]
            });
        };

        (async function () {
            const start_date = moment().subtract(12, "month").format(monthFormat);
            const end_date = moment().subtract(1, "month").format(monthFormat);

            try {
                const resultIncomes = await session.restIncome.getIncomes(partnerId, start_date, end_date);
                let incomes = resultIncomes.data;
                if (incomes) {
                    incomes = incomes.sort((a, b) => a.month <= b.month ? 1 : -1);
                    setIncomes(incomes);
                    buildGraphIncomes(incomes);
                }
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [partnerId, textTrafficGlobal]);

    const handleDownload = () => {
        if (!incomes) return;

        const headers = [
            textTrafficGlobal("table.month"),
            textTrafficGlobal("table.incomes")
        ];

        let rows = incomes.map((income) => [income.month, Intl.NumberFormat(Locale.getCurrentLocale(), {style: "currency", currency: income?.currency}).format(income.hash_incomes)].join(";"));
        rows.unshift(headers.join(";"));

        let universalBOM = "\uFEFF";
        let csvArray = universalBOM + rows.join("\r\n");
        let a = document.createElement("a");
        let blob = new Blob([csvArray], {type: "text/csv;charset=utf-8"});

        let url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = "Sirdata_Incomes.csv";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };

    return (
        <>

            <Loadable loading={isLoading}>
                <ContentBlock header={{title: {label: textTrafficGlobal("generated_incomes")}}}>
                    <Box radius={BoxRadius.MD}>
                        {!!incomes?.length ?
                            <Line data={chartIncomesData} options={optionsLinesMonthlyIncome}/> :
                            <span>{textTrafficGlobal("no_incomes")}</span>
                        }
                    </Box>
                </ContentBlock>
            </Loadable>
            <Loadable loading={isLoading}>
                {!!incomes?.length &&
                    <ContentBlock header={{actions: [<ButtonDownload key={"download-button"} onClick={handleDownload}/>]}}>
                        <Table
                            columns={[
                                {width: 50, label: textTrafficGlobal("table.month"), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 50, label: textTrafficGlobal("table.incomes"), styles: TableColumnStyle.ALIGN_CENTER}
                            ]}
                            themeWhite={PORTAL.theme === Theme.SIRDATA_WHITE}
                        >
                            {incomes.map((income) => <IncomeRow key={income.month} income={income}/>)}
                        </Table>
                    </ContentBlock>
                }
            </Loadable>
        </>
    );
};

export default IncomeSection;
