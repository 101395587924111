import moment from "moment";
import {Locale} from "../common/utils/Locale";
import {Currency} from "./Currency";

export type GraphDataset = {
    label?: string;
    borderColor?: string;
    borderWidth?: number;
    backgroundColor: string | string[];
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointRadius?: number;
    data: number[];
};

export type GraphData = {
    labels: string[];
    datasets: GraphDataset[];
}

export const optionsLinesDaily = {
    barRoundness: 1,
    elements: {
        point: {
            pointStyle: "rectRounded",
            hoverRadius: 3
        }
    },
    scales: {
        xAxes: [{
            type: "time",
            offset: false,
            time: {
                unit: "day",
                displayFormats: {
                    day: "DD/MM"
                }
            },
            gridLines: {display: false}
        }],
        yAxes: [{
            type: "linear",
            offset: false,
            ticks: {
                min: 0
            }
        }]
    },
    tooltips: {
        mode: "index",
        position: "nearest",
        displayColors: true,
        callbacks: {
            title: (tooltipItem: any) => moment(tooltipItem[0].xLabel).format("DD/MM"),
            label: function (tooltipItem: any, data: any) {
                return " " + data.datasets[tooltipItem.datasetIndex].label + ": " + Intl.NumberFormat().format(tooltipItem.yLabel);
            }
        }
    },
    responsive: true
};

export const optionsLinesMonthly = {
    ...optionsLinesDaily,
    scales: {
        xAxes: [{
            time: {
                unit: "month"
            }
        }],
        yAxes: [{
            ticks: {
                callback: (value: number) => Intl.NumberFormat(Locale.getCurrentLocale(), {maximumFractionDigits: 0}).format(value)
            }
        }]
    },
    legend: false
};

export const optionsLinesMonthlyIncome = {
    ...optionsLinesMonthly,
    scales: {
        yAxes: [{
            ticks: {
                callback: (value: number) => Intl.NumberFormat(Locale.getCurrentLocale(), {style: "currency", currency: Currency.EURO, maximumFractionDigits: 0}).format(value)
            }
        }]
    },
    tooltips: {
        callbacks: {
            title: (tooltipItem: any) => moment(tooltipItem[0].xLabel).format("MM/YYYY"),
            label: function (tooltipItem: any, data: any) {
                return " " + data.datasets[tooltipItem.datasetIndex].label + ": " + Intl.NumberFormat(Locale.getCurrentLocale(), {style: "currency", currency: Currency.EURO}).format(tooltipItem.yLabel);
            }
        }
    }
};
