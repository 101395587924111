import {PortalConfig} from "../common/api/PortalConfig";
import {pathHome, sirdataDataPrivacyPolicyUrl} from "../common/utils/constants";
import {HeaderModule} from "../common/utils/HeaderModule";
import {Origin} from "../common/api/model/Origin";
import {Theme} from "../common/utils/Theme";
import {MENU} from "./Menu";
import {pathTermsOfUse} from "./constants";

export const PORTAL = new PortalConfig(Origin.ACCOUNT, pathHome)
    .withMenuItems(MENU)
    .withHeaderModules([HeaderModule.NOTIFICATIONS])
    .withTheme(Theme.SIRDATA_WHITE)
    .withFooter({
        legalNotice: true,
        items: [
            {label: "terms_of_use", path: pathTermsOfUse},
            {label: "privacy_policy", path: sirdataDataPrivacyPolicyUrl}
        ]
    });
