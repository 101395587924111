import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import getApiErrorTranslationKey from "../../api/model/ApiErrors";
import {QueryString} from "../../common/api/model/QueryString";
import {TranslationPortalFile} from "../../utils/constants";
import {pathLogin} from "../../common/utils/constants";
import {AxiosError} from "axios";
import {Alert, AlertSeverity, Box, BoxSpacing, Button, ButtonSize, ContentBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing} from "@sirdata/ui-lib";
import {LogoSirdataOnboarding} from "../../component/snippet";

function ConfirmEmail() {
    const history = useHistory();
    const location = useLocation();
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);

    const [apiError, setApiError] = useState("");

    useEffect(() => {
        (async function () {
            const token = QueryString.getToken(location.search);
            if (!token) {
                history.push(pathLogin);
                return;
            }
            session.clean();
            try {
                await session.restPartnerAccount.confirmEmail(token as string);
            } catch (e) {
                setApiError(getApiErrorTranslationKey((e as AxiosError).message));
            }
        })();
    }, [history, location]);

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form">
                    <div className="main-logo">
                        <LogoSirdataOnboarding/>
                    </div>
                    <ContentBlock>
                        <Box spacing={BoxSpacing.XLARGE}>
                            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.LARGE} alignment={FlexContentAlignment.CENTER}>
                                {apiError ?
                                    <Alert severity={AlertSeverity.DANGER} text={apiError} fullWidth/> :
                                    <Alert severity={AlertSeverity.SUCCESS} text={textOnboarding("confirm_email.success")} fullWidth/>
                                }
                                <Button size={ButtonSize.BIG} onClick={() => history.push(pathLogin)}>{textOnboarding("form.button.login")}</Button>
                            </FlexContent>
                        </Box>
                    </ContentBlock>
                </div>
            </div>
        </div>
    );
}

export default ConfirmEmail;
