import {useState} from "react";
import {useHistory} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {pathHome} from "../../common/utils/constants";
import {redirectToOrigin} from "../helper";

type State = {
    isRegistering: boolean;
    isOriginStepAllowed: boolean;
}

const useRegister = (path: string) => {
    const history = useHistory();
    const [isRegistering, setRegistering] = useState<boolean>(!!sessionStorage.getItem("register"));
    const [isOriginStepAllowed] = useState<boolean>(session.onboarding.isOriginStepAllowed(path));

    const setRegister = () => {
        sessionStorage.setItem("register", "1");
        setRegistering(true);
    };

    const removeRegister = () => {
        sessionStorage.removeItem("register");
        setRegistering(false);
    };

    const goToNext = () => {
        if (session.onboarding.isLastStep(path)) {
            if (!redirectToOrigin()) {
                removeRegister();
                history.push(pathHome);
            }
        } else {
            history.push(session.onboarding.getNextStepPath(path));
        }
    };

    const state: State = {
        isRegistering,
        isOriginStepAllowed
    };

    return [state, {setRegister, goToNext}] as const;
};

export default useRegister;
