import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {
    AlertSeverity,
    Box,
    BoxSpacing,
    Button,
    ButtonLink,
    ButtonLinkStyle,
    ButtonSave,
    ButtonSize,
    ContentBlock,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutButtons,
    FormLayoutRows,
    FormValidationType,
    InputPassword
} from "@sirdata/ui-lib";
import {AxiosError} from "axios";
import validate from "validate.js";

import getApiErrorTranslationKey from "../../api/model/ApiErrors";
import {session} from "../../api/ApiSession";
import {QueryString} from "../../common/api/model/QueryString";
import {TranslationPortalFile} from "../../utils/constants";
import {pathLogin} from "../../common/utils/constants";
import {FormSchema, FormState, LogoSirdataOnboarding} from "../../component/snippet";
import {FormLayoutMessage} from "../../common/component/snippet";

function ResetPassword() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);
    const location = useLocation();
    const history = useHistory();

    const [formState, setFormState] = useState<FormState>({isValid: false, errors: {}});
    const [isShowErrors, setShowErrors] = useState(false);
    const [apiError, setApiError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [passwordForm, setPasswordForm] = useState<{ password: string; confirmPassword: string }>({password: "", confirmPassword: ""});

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!formState.isValid) {
            setShowErrors(true);
            return;
        }
        setLoading(true);
        setShowErrors(false);

        try {
            const token = QueryString.getToken(location.search);
            await session.resetPassword(token as string, passwordForm.password);
            setSubmitted(true);
        } catch (e) {
            setApiError(getApiErrorTranslationKey((e as AxiosError).message));
        } finally {
            setLoading(false);
        }
    };

    const goToLogin = () => history.push(pathLogin);

    useEffect(() => session.saveOrigin(location.search), [location.search]);

    useEffect(() => {
        const errors = validate(passwordForm, FormSchema);
        setFormState({isValid: !errors, errors: errors || {}});
    }, [passwordForm]);

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form">
                    <div className="main-logo">
                        <LogoSirdataOnboarding/>
                    </div>
                    {
                        submitted ?
                            <>
                                <div className="description">{textOnboarding("reset_password.password_changed")}</div>
                                <Button size={ButtonSize.BIG} onClick={goToLogin}>{textOnboarding("form.button.login")}</Button>
                            </> :
                            <>
                                <div className="description">{textOnboarding("reset_password.enter_new_password")}</div>
                                <ContentBlock cssClass="register-form__block">
                                    <Box spacing={BoxSpacing.XLARGE}>
                                        <Form onSubmit={handleSubmit} validationType={FormValidationType.NONE}>
                                            <FormLayoutRows>
                                                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                    <FieldBlock content={{direction: FlexContentDirection.COLUMN, spacing: FlexContentSpacing.XSMALL}}>
                                                        <InputPassword
                                                            placeholder={textOnboarding("form.placeholder.new_password")}
                                                            value={passwordForm.password}
                                                            onChange={(value) => setPasswordForm((prevState) => ({...prevState, password: value}))}
                                                            autoFocus
                                                        />
                                                        {!!(isShowErrors && formState.errors.password) &&
                                                            <FormLayoutMessage message={t(`error.${formState.errors.password}`)} severity={AlertSeverity.DANGER}/>
                                                        }
                                                    </FieldBlock>
                                                    <FieldBlock content={{direction: FlexContentDirection.COLUMN, spacing: FlexContentSpacing.XSMALL}}>
                                                        <InputPassword
                                                            placeholder={textOnboarding("form.placeholder.confirm_new_password")}
                                                            value={passwordForm.confirmPassword}
                                                            onChange={(value) => setPasswordForm((prevState) => ({...prevState, confirmPassword: value}))}
                                                        />
                                                        {!!(isShowErrors && formState.errors.confirmPassword) &&
                                                            <FormLayoutMessage message={t(`error.${formState.errors.confirmPassword}`)} severity={AlertSeverity.DANGER}/>
                                                        }
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                                    <FormLayoutButtons>
                                                        <ButtonSave size={ButtonSize.BIG} disabled={!passwordForm.password || !passwordForm.confirmPassword} loading={isLoading}/>
                                                    </FormLayoutButtons>
                                                    {apiError &&
                                                        <FormLayoutMessage message={t(`error.${apiError}`, t("error.default"))} severity={AlertSeverity.DANGER}/>
                                                    }
                                                </FlexContent>
                                                <FormLayoutButtons>
                                                    <ButtonLink style={ButtonLinkStyle.MIDNIGHT_LIGHT} onClick={goToLogin}>{textOnboarding("form.button.back_to_login")}</ButtonLink>
                                                </FormLayoutButtons>
                                            </FormLayoutRows>
                                        </Form>
                                    </Box>
                                </ContentBlock>
                            </>
                    }
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
